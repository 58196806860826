<template>
  <!-- 需求发布列表 -->
  <div class="zt-page-content">
    <div class="zt-block search-query">
      <div class="clearfix">
        <div class="fl flex tab-menu">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            @click="tab(index)"
            class="menu-item-button"
            :class="{ cur: tabIndex == index }"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="fr">
          <el-row type="flex" :gutter="24">
            <el-col>
              <el-row>
                <el-col>
                  <el-row type="flex" align="middle">
                    <el-col :span="12" class="label">需求类型</el-col>
                    <el-col>
                      <el-select v-model="demandType" @change="getdemandType">
                        <el-option
                          v-for="item in demandTypeOption"
                          :key="item.optionCode"
                          :label="item.optionValue"
                          :value="item.optionCode"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
            <el-col class="search-content">
              <el-input
                placeholder="请输入搜索关键字"
                suffix-icon="el-icon-search"
                @blur="getKeyWords"
                v-model="keywords"
              ></el-input>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div
      class="zt-block"
      style="height: calc(100% - 200px); overflow: hidden; margin-bottom: 10px"
    >
      <div class="list" style="height: 100%; overflow: auto">
        <div
          style="min-width: 1330px"
          v-for="(item, idx) in list"
          :key="idx"
          class="list-item flex"
        >
          <div
            @click="viewDetail(item)"
            style="width: 300px; cursor: pointer; padding-right: 20px"
            class="flex"
          >
            <div style="width: 120px; height: 120px; margin-right: 10px">
              <div
                v-if="
                  item[item.type] &&
                  item[item.type].productImgs &&
                  item[item.type].productImgs.length > 0
                "
                class="bg_img"
                :style="{
                  backgroundImage:
                    'url(' +
                    imgUrl +
                    item[item.type].productImgs[0] +
                    '!width_250px' +
                    ')',
                }"
              ></div>
            </div>
            <div
              v-if="item.title"
              style="text-align: left"
              class="flex-1 flex-justify-between"
            >
              <h3 class="font14-grey ellipsis1">{{ item.title }}</h3>
              <p
                v-if="item[item.type] && item[item.type].cat"
                style="color: #b3b3b3"
              >
                {{ item[item.type].cat.name }}
              </p>
            </div>
          </div>
          <div class="list-item-tr" style="min-width: 200px; max-width: 350px">
            <div class="font14-grey1">需求类型</div>
            <div v-if="item.type">{{ item.type | demandFilt }}</div>
          </div>
          <div class="list-item-tr" style="min-width: 250px; max-width: 350px">
            <div style="color: #b3b3b3">出价预算</div>
            <div
              v-if="item[item.type].prices && item[item.type].prices.length > 0"
            >
              <span v-if="item[item.type].prices[0]"
                >￥{{ item[item.type].prices[0] | priceFilt }}</span
              >
              <span v-if="item[item.type].prices[1]"
                >~{{ item[item.type].prices[1] | priceFilt }}</span
              >
            </div>
            <div v-else-if="item.offers && item.offers.length > 0">
              ￥{{ item.offers[0][item.type].price | priceFilt }}
            </div>
          </div>
          <div class="list-item-tr" style="width: 180px">
            <div style="color: #b3b3b3">
              <div v-if="item.type == 'dayang'">期望验收时间</div>
              <div v-else-if="item.type == 'gaikuang'">期望交货时间</div>
              <div v-else>开始交货时间</div>
            </div>
            <div
              v-if="
                item.type == 'dayang' &&
                item[item.type] &&
                item[item.type].checkDate
              "
            >
              {{ item[item.type].checkDate | moment("YYYY-MM-DD") }}
            </div>
            <div
              v-if="
                item.type != 'dayang' &&
                item[item.type] &&
                item[item.type].giveDate
              "
            >
              {{ item[item.type].giveDate | moment("YYYY-MM-DD") }}
            </div>
          </div>
          <div class="list-item-tr" style="width: 180px">
            <div style="color: #b3b3b3">
              <div v-if="item.type == 'peitao'">需求量</div>
              <div v-if="item.type == 'gaikuang'">设计类型</div>
              <div v-if="item.type == 'dayang'">需求量</div>
            </div>
            <div>
              <div
                v-if="
                  item.type == 'peitao' &&
                  item[item.type] &&
                  item[item.type].count
                "
              >
                {{ item[item.type].count }}
                <span class="font14-grey1">
                  个
                  <span v-if="item[item.type] && item[item.type].type == 2"
                    >/月</span
                  >
                </span>
              </div>
              <div
                v-if="
                  item.type == 'dayang' &&
                  item[item.type] &&
                  item[item.type].count
                "
              >
                {{ item[item.type].count }}
                <span class="font14-grey1">个</span>
              </div>
              <div
                v-if="
                  item.type == 'gaikuang' &&
                  item[item.type] &&
                  item[item.type].fileTypes.length > 0
                "
              >
                <div
                  v-for="(item1, idx1) in item[item.type].fileTypes"
                  :key="idx1"
                >
                  <div v-if="item1 == '3d'">3d渲染图</div>
                  <div v-if="item1 == 'material'">电商素材</div>
                  <div v-if="item1 == 'size'">尺寸图</div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="min-width: 150px; padding: 5px 15px"
            class="list-item-tr flex-1"
          >
            <div>
              <el-button
                @click="republish(item)"
                v-if="item.state == 5"
                type="text"
                >重新发起</el-button
              >
              <el-button
                @click="edit(item, tabIndex)"
                v-if="item.state == 1"
                type="text"
                >继续编辑</el-button
              >
              <el-button
                @click="issue(item, tabIndex)"
                v-if="item.state == 1"
                type="text"
                >发布</el-button
              >
              <el-button
                @click="close(item)"
                v-if="[2, 3].includes(item.state)"
                size="mini"
                type="primary"
                plain
                round
                >关闭需求</el-button
              >
              <el-button
                @click="goOrder(item)"
                v-if="item.state == 4"
                size="mini"
                type="primary"
                plain
                round
                >查看需求订单</el-button
              >
              <el-button
                @click="
                  goto(
                    '/want/wantFollow/pipeList/' +
                      item.id +
                      '?type=' +
                      item.type
                  )
                "
                v-if="item.state >= 2 && item.state <= 5"
                type="text"
              >
                <span v-if="['peitao', 'dayang'].includes(item.type)">
                  查看工厂应答
                  <span>({{ item.offers ? item.offers.length : 0 }})</span>
                </span>
                <span v-if="['gaikuang'].includes(item.type)">
                  查看设计公司应答
                  <span>({{ item.offers ? item.offers.length : 0 }})</span>
                </span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 7px">
      <el-pagination
        background
        layout="prev, pager, next, total"
        :current-page.sync="queryOpt.page.pageNumber"
        :page-size="queryOpt.page.pageCount"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { IMG_URL_PRE } from "@/config";
import { demandTypeOption } from "@/utils/constant";
import {
  getRequireList,
  add_pubRequire,
  copy_pubRequire,
  dcRequire,
  closeRequire,
  delRequire,
  requireOfferList,
} from "@/service/want";
export default {
  components: {},
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      tabList: [
        { name: "已发布" },
        { name: "已达成" },
        { name: "已关闭" },
        { name: "草稿" },
      ],
      tabIndex: 0,
      queryOpt: {
        where: {
          ids: null,
          key: null,
          types: ["dayang", "gaikuang", "peitao"],
          states: [2, 3],
          compIds: null,
        },
        page: {
          pageNumber: 1,
          pageCount: 10,
        },
      },
      demandType: 0,
      demandTypeOption,
      keywords: "",
      total: 0,
      list: [],
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true,
    },
  },
  created() {
    this.comp = this.$store.state.loginUser.comp;
    if (this.comp && this.comp.comp_type) {
      switch (this.comp.comp_type) {
        // 工厂
        case 1:
          this.queryOpt.where.types = ["peitao", "gaikuang", "dayang"];
          this.demandTypeOption = [
            { optionCode: 0, optionValue: "全部" },
            ...demandTypeOption,
          ];
          break;
        // 销售公司
        case 3:
          this.queryOpt.where.types = ["peitao", "gaikuang"];
          this.demandTypeOption = [
            { optionCode: 0, optionValue: "全部" },
            ...demandTypeOption.filter((item) => item.optionCode !== "dayang"),
          ];
          break;
      }
    }
    this.queryOpt.where.offerCompIdsNotIn = [this.comp.id];
    this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
    this.refreshItems();
  },
  methods: {
    goOrder(item) {
      this.$router.push({
        path: "/factory/demandOrder",
        query: {
          type:
            item.type === "dayang" ? 0 : item.type === "gaikuang" ? 1 : null,
        },
      });
    },
    getdemandType(val) {
      this.queryOpt.where.types = [val];
    },
    getKeyWords() {
      this.queryOpt.where.key = this.keywords;
    },
    goto(link) {
      this.$router.push(link);
    },
    viewDetail(item) {
      if (item.type == "dayang") {
        requireOfferList(item.id)
          .then((rst) => {
            if (rst.rows.length > 0) {
              this.$router.push(
                "/want/wantFollow/pipe/detail/" +
                  item.id +
                  "/" +
                  rst.rows[0].id +
                  "?type=" +
                  item.type
              );
            } else {
              this.$router.push("wantFollow/want/detail/" + item.id);
            }
            console.log(rst);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$router.push("wantFollow/want/detail/" + item.id);
      }
    },
    // 选项卡
    tab(index) {
      this.tabIndex = index;
      this.queryOpt.where.states = [index + 3];
      if (index == 0) this.queryOpt.where.states = [2, 3];
      if (index == 3) this.queryOpt.where.states = [1];
      this.refreshItems();
    },
    refreshItems() {
      getRequireList(this.queryOpt)
        .then((rst) => {
          if (rst.rows && rst.rows.length > 0) {
            this.total = rst.count;
            this.list = rst.rows;
          } else {
            this.total = 0;
            this.list = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    issue(item) {
      let _item = _.clone(item);
      this.$confirm("确认是否发布?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        add_pubRequire(_item)
          .then((rst) => {
            this.tabIndex = 0;
            this.queryOpt.where.states = [2, 3];
            this.refreshItems();
            this.$message.success("发布成功");
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      });
    },
    republish(item) {
      this.$confirm("确认是否重新发布该需求?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        copy_pubRequire(item.id)
          .then((rst) => {
            this.tabIndex = 0;
            this.queryOpt.where.states = [2];
            this.refreshItems();
            this.$message.success("重新发布成功");
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      });
    },
    edit(item) {
      this.$router.push({
        path: "/want/wantIssue",
        query: { id: item.id, type: item.type },
      });
    },
    dc_require(item) {
      this.$confirm("确认是否达成该需求?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        dcRequire(item.id)
          .then((rst) => {
            this.tabIndex = 1;
            this.queryOpt.where.states = [4];
            this.refreshItems();
            this.$message.success("达成成功");
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      });
    },
    close(item) {
      this.$confirm("确认是否关闭?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        closeRequire(item.id)
          .then((rst) => {
            this.tabIndex = 2;
            this.queryOpt.where.states = [5];
            this.refreshItems();
            this.$message.success("关闭成功");
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      });
    },
    del(item) {
      this.$confirm("确认是否删除?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRequire(item.id)
          .then((rst) => {
            this.refreshItems();
            this.$message.success("删除成功");
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.block {
  margin: 20px 20px;
  /* background: transparent; */
}
/*----选项卡----*/
.tab_menu {
  min-width: 680px;
  /* background: #fff; */
  padding: 10px 0;
  margin: 20px 10px;
}
.tab_menu .menu_item {
  height: 40px;
  line-height: 40px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur .menu_item_button {
  color: #4d4d4d;
  background: linear-gradient(
    90deg,
    rgba(131, 98, 228, 1) 0%,
    rgba(80, 116, 238, 1) 100%
  );
  box-shadow: 0px 2px 4px 0px rgba(107, 132, 219, 1);
  border: none;
  color: #fff;
}
.menu_item_button {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  border: 1px solid rgba(179, 179, 179, 1);
  font-size: 14px;
  color: #808080;
}
.list > .list-item {
  padding: 20px;
  background: #fff;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid rgba(230, 230, 230, 1);
  font-size: 14px;
  color: #4d4d4d;
}
.list > .list-item:last-child {
  margin-bottom: 0;
}
.list > .list-item > div {
  min-width: 180px;
  min-height: 120px;
  padding: 0 30px;
  border-right: 1px solid #e6e6e6;
}
.list > .list-item > div:first-child {
  padding-left: 0;
}
.list > .list-item > div:last-child {
  border-right: none;
}
.list > .list-item .list-item-tr > div:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
  word-wrap: break-word;
}
.pageCount {
  margin: 0 20px 10px 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #4d4d4d;
}
.list-item-tr >>> button {
  margin: 5px;
}
.alert-red {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f66f6a;
  border-radius: 50%;
  margin-left: 5px;
}
</style>